import logo from './logo.svg';
import './App.css';
import datos from './datos.json';

function App() {


  
  const url = document.URL
  const urlm = document.URL.length - 6
  const urlg = url.substring(urlm)

  console.log(urlg)

  for( let i=0; i < datos.length; i++){
            
            
    if(datos[i].id2 == urlg){
      console.log(urlg)
        const count = datos[i].id 
        console.log(count)
        window.location.href='https://prepaenlinea.sep.gob.mx.validacion.info/?tipo=5&subtipo=0&folio=0&id='+count
        
    }
    
   
    
}
  return (


    
    <div className="App">
      
    </div>
  );
}

export default App;
